import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Image from "gatsby-image"
import { Heading, AspectRatio, Link, Text, Grid, Flex, Box } from "theme-ui"
import SwipeableViews from "react-swipeable-views"

import BlockText from "../utils/block-text"
import Layout from "../components/Layout"
import Synopsis from "../components/Synopsis"
import SoldOut from "../components/SoldOut"

export const query = graphql`
  query($id: String!) {
    sanityShopifyCollection(id: { eq: $id }) {
      id
      title
      intro
      number
      _rawSynopsis
      _rawNote
      book {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      products {
        id
        heading
        colour
        productType
        maxVariantPrice
        variants {
          shopifyVariantID
          title
          sourceData {
            availableForSale
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`

const EpisodeTemplate = ({ data }) => {
  const {
    title,
    intro,
    products,
    book,
    _rawNote,
  } = data.sanityShopifyCollection

  return (
    <Layout>
      <Box bg="background" px={[24, "10%"]} pt={[4, 5]}>
        <Grid gap={[4, 6]} columns={[1, "2fr 1fr"]}>
          <Flex sx={{ flexDirection: "column" }}>
            <Heading as="h1" variant="subtitle">
              {title}
            </Heading>
            <Text sx={{ fontSize: [2, 3, 5], mb: [4, 5] }}>{intro}</Text>

            <Box sx={{ mt: "auto", fontSize: [0, 1], p: { mb: 0 } }}>
              <BlockText blocks={_rawNote} />
            </Box>
          </Flex>

          <Box
            sx={{
              width: "100%",
              maxWidth: [120, 280],
              ml: [0, "auto"],
            }}
          >
            <Image fluid={book.asset.fluid} />
          </Box>
        </Grid>
      </Box>

      <Box bg="background" px={[24, "10%"]} py={[4, 5]}>
        <Grid gap={4} columns={[1, 2]}>
          {products.map(
            ({
              id,
              heading,
              productType,
              colour,
              maxVariantPrice,
              slug,
              images,
              variants,
            }) => {
              const price = new Intl.NumberFormat("en-AU", {
                style: "currency",
                currency: "AUD",
              }).format(maxVariantPrice)
              const hasVariants = variants.length > 1
              const isVariantsOutOfStock =
                hasVariants &&
                variants.every((x) => x.sourceData.availableForSale === false)
              const isOutOfStock =
                (!hasVariants && !variants[0].sourceData.availableForSale) ||
                isVariantsOutOfStock

              return (
                <Box
                  key={id}
                  sx={{
                    color: "text",
                    "&:hover": {
                      color: "highlight",
                      span: {
                        color: "primary",
                      },
                    },
                  }}
                >
                  <AspectRatio ratio={1440 / 1652}>
                    <Link as={GatsbyLink} to={`/products/${slug.current}`}>
                      <SwipeableViews disableLazyLoading enableMouseEvents>
                        {images.map((image, idx) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Image key={idx} fluid={image.asset.fluid} />
                        ))}
                      </SwipeableViews>
                      {isOutOfStock && (
                        <Flex
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: "rgba(2, 2, 2, 0.4)",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SoldOut width="100%" />
                        </Flex>
                      )}
                    </Link>
                  </AspectRatio>

                  <Link
                    as={GatsbyLink}
                    to={`/products/${slug.current}`}
                    sx={{
                      textDecoration: "none",
                      textAlign: "center",
                      textTransform: "uppercase",
                      display: "block",
                      fontSize: 0,
                      color: "inherit",
                      fontWeight: "bold",
                      padding: "20px 10px",
                      transition: "color 0.3s ease",
                    }}
                  >
                    {heading} {productType} / {colour}
                    <Text
                      as="span"
                      sx={{
                        color: "gray",
                        marginLeft: "10px",
                        display: "inline-block",
                        transition: "color 0.3s ease",
                      }}
                    >
                      {price}
                    </Text>
                  </Link>
                </Box>
              )
            }
          )}
        </Grid>
      </Box>

      <Synopsis collections={[data.sanityShopifyCollection]} />
    </Layout>
  )
}
export default EpisodeTemplate
